import api from "src/api/Api";
import urls from "src/api/Urls";


export const searchBlocks = (params) => {
    return api.get(urls.block_search, { params: params });
}

export const yourBlocks = (params) => {
    return api.get(urls.your_blocks, { params: params });
}

export const deallocateBlock = (payload) => {
    return api.post(urls.deallocate_block, payload);
}

export const blockInfo = (params) => {
    return api.get(urls.block_info, { params: params });
}