import PortalLayout from 'src/layouts/PortalLayout';
import { Number } from './number';
import { useTranslation } from 'react-i18next';
import YourBlocks from './block/YourBlocks';
import { useStore } from 'src/store/Store';
// ----------------------------------------------------------------------


export default function NumbersSidebar() {
    const { t } = useTranslation();
    const [store] = useStore();

    const displayBlocks = store.portalSettings.services.portal_features.display_block_number_search ?? false;

    const TABS = [
        { id: 0, label: t('numbers-0'), component: <Number /> },
    ];

    if (displayBlocks) {
        TABS.push({ id: 1, label: t('Blocks'), component: <YourBlocks /> });
    }

    const TITLE = t('numbers-0');

    return (
        <PortalLayout tabs={TABS} title={TITLE} />
    );
}
