import React, { useEffect } from 'react';
import {
    List,
    ListItemText,
    Divider,
    ListItem,
    Box,
    Tooltip,
    IconButton,
    CircularProgress
}
    from '@mui/material';
import { useTranslation } from 'react-i18next';
import { PortingService, BlockService } from 'src/api/services';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import BaseSnackbar from 'src/components/BaseSnackbar';
import {useTheme} from '@mui/styles'
import BaseButton from 'src/components/buttons/BaseButton';
import ViewDialog from "./Forms/ViewDialog";
import ScheduleDialog from "./Forms/ScheduleDialog";
import BaseModal from 'src/components/BaseModal';
import { Edit, Visibility } from '@mui/icons-material';
import { useStore } from 'src/store/Store';

export default function BlockInfo() {

    const { t } = useTranslation();
    const [data, setData] = useState([]);
    const { id } = useParams();
    const [openSnackbar, setSnackbarStatus] = useState(false);
    const [message, setMessage] = useState("");
    const navigate = useNavigate();

    const [store] = useStore();

    const providersEnabled = store.portalSettings.services.access_management.display_provider_codes ?? false;

    const fetchBlock = async () => {
        BlockService.blockInfo({ block_id: id })
            .then((response) => {
                console.log(response.data.data);
                if (response.data.meta.code !== 200) {
                    navigate(`./not-found`);
                }
                setData(response.data.data);
            })
            .catch((err) => {
                setMessage(err);
                setSnackbarStatus(true);
            });
    };

    useEffect(() => {
        fetchBlock();
        return () => {
            setData([]);
        };
    }, [id]);

    const theme = useTheme();
    const [selectedRow, setSelectedRow] = useState({});
    const [openInfoDialog, setOpenInfoDialog] = useState(false);
    const [openScheduleDialog, setOpenScheduleDialog] = useState(false);

    const modalHandler = (modalType, id = undefined) => {
        if (modalType === "infoDialog") {
            setSelectedRow(data.find((row) => row.did_id === id));
            setOpenInfoDialog(true);
        }
        if (modalType === "scheduleDialog") {
            setSelectedRow(data.find((row) => row.did_id === id));
            setOpenScheduleDialog(true);
        }
    }


    return(


        <>
            <BaseSnackbar open={openSnackbar} message={message} setOpen={setSnackbarStatus}/>
            {data.length > 0 ? (<Box sx= {{
                backgroundColor : 'white',
                border:'3em solid white',
                borderColor: theme.palette['--card-background-color'],
                borderRadius: 2,
                boxShadow: 12,
                margin: "auto",
                maxWidth: 800,

            }}>

            <BaseModal title={'Translation Details - ' + selectedRow.did} open={openInfoDialog} setOpen={setOpenInfoDialog} children={<ViewDialog successCallback={fetchBlock} formData={selectedRow} setModalStatus={setOpenInfoDialog} setSnackbarStatus={setSnackbarStatus} setMessage={setMessage} />} />
            <BaseModal title={'Translation Details - ' + selectedRow.did} open={openScheduleDialog} setOpen={setOpenScheduleDialog} children={<ScheduleDialog successCallback={fetchBlock} formData={selectedRow} setModalStatus={setOpenScheduleDialog} setSnackbarStatus={setSnackbarStatus} setMessage={setMessage} />} />
             
                <List component="nav" aria-label="mailbox folders" >
                    <h1>Block #{id}</h1>
                    <br/>
                    <ListItem className='ListItem' >
                        <ListItemText primary= {t("Block ID")} />
                        {data[0].block_id}
                    </ListItem>
                    <Divider />
                    <ListItem >
                        <ListItemText primary={t("Country")} />
                        {data[0].country}
                    </ListItem>
                    <Divider/>
                    <ListItem >
                        <ListItemText primary={t("Capability")} />
                        {data[0].capability}
                    </ListItem>
                    {providersEnabled && (
                    <>
                    <Divider/>
                    <ListItem >
                        <ListItemText primary={t("Provider Code")} />
                        {data[0].provider_code}
                    </ListItem>
                    </>
                    )}
                    <Divider/>

                    
                    <ListItem>
                        <ListItemText primary={t("Numbers")+":"} />
                        
                    </ListItem>
                    <>
                    {data.map((item, index) => {
                            return (
                                <div key={item.did_id}>
                                    <ListItem selected={index % 2 == 0 ? true : false} >
                                    <ListItemText primary={item.did} />
                                    <>
                     <IconButton sx = {{display: "flex", justifyContent: "flex-start"}}>
                        <Visibility
                            sx = {{
                                width:30,
                                height:30,
                                borderRadius:0.5,
                                color: "white",
                                backgroundColor: theme.palette['--color-success']}}
                            onClick={() => modalHandler("infoDialog", item.did_id)}
                        />
                    </IconButton>

                    <IconButton sx = {{display: "flex", justifyContent: "flex-start"}}>
                        <Edit
                            sx = {{
                                width:30,
                                height:30,
                                borderRadius:0.5,
                                color: "white",
                                backgroundColor: theme.palette['--color-success']}}
                            onClick={() => modalHandler("scheduleDialog", item.did_id)}/>
                    </IconButton>

                    </>
                                    </ListItem>
                                </div>
                            );
                        })}
                    </>

                 
                  
               
                </List>
            </Box>) :<Box sx= {{
                backgroundColor : 'white',
                border:'3em solid white',
                borderColor: theme.palette['--card-background-color'],
                borderRadius: 2,
                boxShadow: 12,
                margin: "auto",
                maxWidth: 800,
                alignItems: 'center',
                justifyContent: 'center',
                display: 'flex',

            }}> <CircularProgress/></Box>}


        </>

    )
}
